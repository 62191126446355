#error {
	.col-sm-12 {
		@include padding-similarities(35px);
		margin:175px 0 300px 0;
		@media only screen and (max-width:1399px) {
			margin-top:100px;
		}
		@media only screen and (max-width:1199px) {
			margin-top:50px;
		}
		@media only screen and (max-width:650px) {
			margin-bottom:35px;
		}
		.col-sm-6 {
			img {
				margin-right:85px;
				width:100%;
				@media only screen and (max-width:1399px) {
					margin-top:75px;
				}
				@media only screen and (max-width:1199px) {
					margin-top:125px;
				}
				@media only screen and (max-width:991px) {
					margin-top:150px;
				}
				@media only screen and (max-width:650px) {
					margin-top:0;
				}
			}
			h1 {
				font-family: $font-secondary;
				font-size:10.929em;
				font-weight: $weight-light;
				color: $error-heading;
				min-height: 100px;
				padding-top:155px;
				@media only screen and (max-width:650px) {
					padding-top:0;
				}
			}
			p {
				font-size: 1.786em;
				color: $error-text;
				margin: 55px 0 30px 0;
				font-weight: $weight-light;
				@media only screen and (max-width:1199px) {
					line-height: 32px;
				}
			}
			div {
				@include flexbox;
				@include justify-content(flex-start);
			}
		}
	}
}
