//FLEX
@mixin dFlex(){
  display:flex;
  display:-webkit-flex;
  flex-wrap:wrap;
  -webkit-flex-wrap:wrap;
}

// FLEX CENTER HORIZONTAL
@mixin dCentercenter(){
    @include flexbox;
    @include flex-wrap(wrap);
    @include justify-content(center);
}

// FLEX VERTICAL CENTER
@mixin dVerticalcenter(){
  @include flexbox;
  @include align-items(center)
}

// FLEX VERTICAL HORIZONTAL ET VERTICAL
@mixin dflexTotal(){
  @include flexbox;
  @include align-items(center);
  @include flex-wrap(wrap);
  @include justify-content(center);
}

@mixin flexbox {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
}

@mixin justify-content($value: flex-start) {
    @if $value == flex-start {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
    } @else if $value == flex-end {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
    } @else if $value == space-between {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
    } @else if $value == space-around {
        -ms-flex-pack: distribute;
    } @else {
        -webkit-box-pack: $value;
        -ms-flex-pack: $value;
    }
    -webkit-justify-content: $value;
    -moz-justify-content: $value;
    justify-content: $value;
}

@mixin align-items($value: stretch) {
    @if $value == flex-start {
        -webkit-box-align: start;
        -ms-flex-align: start;
    } @else if $value == flex-end {
        -webkit-box-align: end;
        -ms-flex-align: end;
    } @else {
        -webkit-box-align: $value;
        -ms-flex-align: $value;
    }
    -webkit-align-items: $value;
    -moz-align-items: $value;
    align-items: $value;
}

@mixin flex-wrap($value: nowrap) {
    // No Webkit Box fallback.
    -webkit-flex-wrap: $value;
    -moz-flex-wrap: $value;
    @if $value == nowrap {
        -ms-flex-wrap: none;
    } @else {
        -ms-flex-wrap: $value;
    }
    flex-wrap: $value;
}

//BORDER RADIUS KIT
@mixin border-radius($radius){
     -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
     border-radius: $radius;
}
//MULTIPLE BORDER RADIUS KIT
@mixin borderRadiusx4($radiusTop, $radiusRight, $radiusBottom, $radiusLeft){
     -webkit-border-radius: $radiusTop $radiusRight $radiusBottom $radiusLeft;
     -moz-border-radius: $radiusTop $radiusRight $radiusBottom $radiusLeft;
     border-radius: $radiusTop $radiusRight $radiusBottom $radiusLeft;
}

// TRANSFORM
@mixin transform($degrees){
  -moz-transform: rotate($degrees);
  -o-transform: rotate($degrees);
  -webkit-transform: rotate($degrees);
  transform: rotate($degrees);
}
// BOX SHADOW KIT
@mixin box-shadow($string: 0 1px 3px 0 rgba(0, 0, 0, 0.25)){
  -webkit-box-shadow: $string;
  -moz-box-shadow:    $string;
  box-shadow:         $string;
}

// HEIGHT LINEHEIGHT SIMILAIRES
@mixin height-lineheightsimilarities($height-lineheight){
  height:$height-lineheight;
  line-height: $height-lineheight;
}
// HEIGHT + LINEHEIGHT
@mixin height-lineheight($valHeight, $vallineHeight){
  height:$valHeight;
  line-height: $vallineHeight;
}
// WIDTH + LINEHEIGHT
@mixin width-lineheight($valWidth, $vallineHeight){
  width:$valWidth;
  line-height: $vallineHeight;
}
// WIDTH + HEIGHT
@mixin width-height($valWidth, $valHeight){
  width:$valWidth;
  height:$valHeight;
}
// WIDTH + HEIGHT SIMILARITIES
@mixin width-heightsimilarities($width-heightsimilarities){
  width:$width-heightsimilarities;
  height:$width-heightsimilarities;
}
// WIDTH + HEIGHT + LINE-HEIGHT
@mixin width-heightlineheight($valWidth, $valHeight, $vallineHeight){
  width:$valWidth;
  height:$valHeight;
  line-height: $vallineHeight;
}
// WIDTH + HEIGHT/LINE-HEIGHT SIMILARITIES
@mixin width-heightlineheightsimilarities($valWidth, $height-lineheight){
  width:$valWidth;
  @include height-lineheightsimilarities ($height-lineheight);
}

// PADDING LEFT = PADDING RIGHT
@mixin padding-similarities($padding-similarities){
  padding-left:$padding-similarities;
  padding-right: $padding-similarities;
}

// MARGIN LEFT = MARGIN RIGHT
@mixin margin-similarities($margin-similarities){
  margin-left:$margin-similarities;
  margin-right: $margin-similarities;
}

// BEFORE/AFTER : POSITION ABSOLUE + CONTENT
  @mixin content-abs(){
  position:absolute;
  content:'';
}

// CALCUL LARGEUR PAR 100
// exemple : ((53px))
@mixin widthCalc($widthCalc){
  width:calc#{"(100% - #{$widthCalc})"};
  width:-moz-calc#{"(100% - #{$widthCalc})"};
  width:-webkit-calc#{"(100% - #{$widthCalc})"};
  width:#{"calc(100% - #{$widthCalc})"};
  width:#{"-moz-calc(100% - #{$widthCalc})"};
  width:#{"-webkit-calc(100% - #{$widthCalc})"};
}
// CALCUL LARGEUR PAR 50
// exemple : ((53px))
@mixin widthCalc50($widthCalc){
  width:calc#{"(50% - #{$widthCalc})"};
  width:-moz-calc#{"(50% - #{$widthCalc})"};
  width:-webkit-calc#{"(50% - #{$widthCalc})"};
  width:#{"calc(50% - #{$widthCalc})"};
  width:#{"-moz-calc(50% - #{$widthCalc})"};
  width:#{"-webkit-calc(50% - #{$widthCalc})"};
}
// CALCUL LARGEUR PAR 25
// exemple : ((53px))
@mixin widthCalc25($widthCalc){
  width:calc#{"(25% - #{$widthCalc})"};
  width:-moz-calc#{"(25% - #{$widthCalc})"};
  width:-webkit-calc#{"(25% - #{$widthCalc})"};
  width:#{"calc(25% - #{$widthCalc})"};
  width:#{"-moz-calc(25% - #{$widthCalc})"};
  width:#{"-webkit-calc(25% - #{$widthCalc})"};
}
// VARIABLE BOTTOM (ne pas forcement s'en servir dans CSS)
// ex : (10px)
@mixin abs-bottom($abs-bottom){
  bottom:$abs-bottom;
}

// VARIABLE TOP (ne pas forcement s'en servir dans CSS)
// ex : (10px)
@mixin abs-top($abs-top){
  top:$abs-top;
}

// VARIABLE LEFT (ne pas forcement s'en servir dans CSS)
// ex : (10px)
@mixin abs-left($abs-left){
  left:$abs-left;
}

// VARIABLE RIGHT (ne pas forcement s'en servir dans CSS)
// ex : (10px)
@mixin abs-right($abs-right){
  right:$abs-right;
}

// VARIABLE CALCUL BOTTOM PAR 50 (ne pas forcement s'en servir dans CSS)
// ex : ((53px))
@mixin abs-bottomcalc($abs-bottomcalc){
  bottom:calc#{"(50% - #{$abs-bottomcalc})"};
  bottom:-moz-calc#{"(50% - #{$abs-bottomcalc})"};
  bottom:-webkit-calc#{"(50% - #{$abs-bottomcalc})"};
  bottom:#{"calc(50% - #{$abs-bottomcalc})"};
  bottom:#{"-moz-calc(50% - #{$abs-bottomcalc})"};
  bottom:#{"-webkit-calc(50% - #{$abs-bottomcalc})"};
}

// VARIABLE CALCUL TOP PAR 50 (ne pas forcement s'en servir dans CSS)
// ex : ((53px))
@mixin abs-topcalc($abs-topcalc1){
  top:calc(50% - #{$abs-topcalc1});
top:-moz-calc#{"(50% - #{$abs-topcalc1})"};
  top:-webkit-calc#{"(50% - #{$abs-topcalc1})"};
  top:#{"calc(50% - #{$abs-topcalc1})"};
  top:#{"-moz-calc(50% - #{$abs-topcalc1})"};
  top:#{"-webkit-calc(50% - #{$abs-topcalc1})"};
}

// VARIABLE CALCUL LEFT PAR 50 (ne pas forcement s'en servir dans CSS)
// ex : ((53px))
@mixin abs-leftcalc($abs-leftcalc){
  left:calc#{"(50% - #{$abs-leftcalc})"};
  left:-moz-calc#{"(50% - #{$abs-leftcalc})"};
  left:-webkit-calc#{"(50% - #{$abs-leftcalc})"};
  left:#{"calc(50% - #{$abs-leftcalc})"};
  left:#{"-moz-calc(50% - #{$abs-leftcalc})"};
  left:#{"-webkit-calc(50% - #{$abs-leftcalc})"};
}

//VARIABLE CALCUL RIGHT PAR 50 (ne pas forcement s'en servir dans CSS)
// ex : ((53px))
@mixin abs-rightcalc($abs-rightcalc){
  right:calc#{"(50% - #{$abs-rightcalc})"};
  right:-moz-calc#{"(50% - #{$abs-rightcalc})"};
  right:-webkit-calc#{"(50% - #{$abs-rightcalc})"};
  right:#{"calc(50% - #{$abs-rightcalc})"};
  right:#{"-moz-calc(50% - #{$abs-rightcalc})"};
  right:#{"-webkit-calc(50% - #{$abs-rightcalc})"};
}

// POSITION ABSOLUTE + TOP + LEFT
// ex : (15px, 20px)
@mixin absolute-topleft($topAbs, $leftAbs){
  position:absolute;
  top:$topAbs;
  left: $leftAbs;
}
// POSITION FIXED + TOP + LEFT
// ex : (15px, 20px)
@mixin fixed-topleft($topAbs, $leftAbs){
  position:fixed;
  top:$topAbs;
  left: $leftAbs;
}
// POSITION FIXED + TOP + RIGHT
// ex : (15px, 20px)
@mixin fixed-topright($topAbs, $rightAbs){
  position:fixed;
  top:$topAbs;
  right: $rightAbs;
}
// POSITION FIXED + BOTTOM + LEFT
// ex : (15px, 20px)
@mixin fixed-bottomleft($bottomAbs, $leftAbs){
  position:fixed;
  bottom:$bottomAbs;
  left: $leftAbs;
}
//  POSITION ABSOLU + TOP + LEFT CALC
// ex : (15px, (20px)
@mixin absolute-topleftcontent($abs-top, $abs-leftcalc){
  position:absolute;
  @include abs-top ($abs-top);
  @include abs-leftcalc ($abs-leftcalc);
}

// BEFORE/AFTER + TOP + LEFT
// ex : (15px, 20px)
@mixin abs-topleftcontent($abs-top, $abs-left){
  @include content-abs();
  @include abs-top ($abs-top);
  @include abs-left ($abs-left);
}

// POSITION ABSOLUTE + TOP + RIGHT
// ex : (15px, 20px)
@mixin absolute-topright($abs-top, $abs-right){
  position: absolute;
  @include abs-top ($abs-top);
  @include abs-right ($abs-right);
}

// BEFORE/AFTER + TOP + RIGHT
// ex : (15px, 20px)
@mixin abs-toprightcontent($abs-top, $abs-right){
  @include content-abs();
  @include abs-top ($abs-top);
  @include abs-right ($abs-right);
}

// POSITION ABSOLUTE + BOTTOM + LEFT
// ex : (15px, 20px)
@mixin absolute-bottomleft($abs-bottom, $abs-left){
  position: absolute;
  @include abs-bottom ($abs-bottom);
  @include abs-left ($abs-left);
}

// POSITION ABSOLUTE + BOTTOM + RIGHT
// ex : (15px, 20px)
@mixin absolute-bottomright($abs-bottom, $abs-right){
  position: absolute;
  @include abs-bottom ($abs-bottom);
  @include abs-right ($abs-right);
}

// BEFORE/AFTER + BOTTOM + RIGHT
// ex : (15px, 20px)
@mixin abs-bottomrightcontent($abs-bottom, $abs-right){
  @include content-abs();
  @include abs-bottom ($abs-bottom);
  @include abs-right ($abs-right);
}

// BEFORE/AFTER + BOTTOM + LEFT CALC
// ex : (15px, (20px)
@mixin abs-bottomleftcontent($abs-bottom, $abs-leftcalc){
  @include content-abs();
  @include abs-bottom ($abs-bottom);
  @include abs-leftcalc ($abs-leftcalc);
}
//  POSITION ABSOLU + BOTTOM + LEFT CALC
// ex : (15px, (20px)
@mixin absolute-bottomleftcontent($abs-bottom, $abs-leftcalc){
  position:absolute;
  @include abs-bottom ($abs-bottom);
  @include abs-leftcalc ($abs-leftcalc);
}

// BEFORE/AFTER + BOTTOM + LEFT
// ex : (15px, (20px)
@mixin content-bottomleftnocalc($abs-bottom, $abs-left){
  @include content-abs();
  @include abs-bottom ($abs-bottom);
  @include abs-left ($abs-left);
}

// BEFORE/AFTER + TOP CALCUL PAR 50 + LEFT CALCUL PAR 50
// ex : ((10px),(25px))
@mixin abs-topcalcleftcalccontent($abs-topcalc1, $abs-leftcalc){
  @include content-abs();
  @include abs-topcalc ($abs-topcalc1);
  @include abs-leftcalc ($abs-leftcalc);
}

// POSITION ABSOLU + TOP CALCUL PAR 50 + LEFT CALCUL PAR 50
// ex : ((10px),(25px))
@mixin absolute-topcalcleftcalc($abs-topcalc, $abs-leftcalc){
  position: absolute;
  @include abs-topcalc ($abs-topcalc);
  @include abs-leftcalc ($abs-leftcalc);
}

// POSITION ABSOLU + BOTTOM CALCUL PAR 50 + LEFT CALCUL PAR 50
// ex : ((10px),(25px))
@mixin absolute-bottomcalcleftcalc($abs-bottomcalc, $abs-leftcalc){
  position: absolute;
  @include abs-bottomcalc ($abs-bottomcalc);
  @include abs-leftcalc ($abs-leftcalc);
}

// BEFORE/AFTER + TOP CALCUL PAR 50 + RIGHT CALCUL PAR 50
// ex : ((10px),(25px))
@mixin abs-topcalcrightcalccontent($abs-topcalc, $abs-rightcalc){
  @include content-abs();
  @include abs-topcalc ($abs-topcalc);
  @include abs-rightcalc ($abs-rightcalc);
}

// BEFORE/AFTER + TOP ABSOLUE + LEFT CALCUL PAR 50
// ex : (0, (30px));
@mixin abs-topleftcalccontent($abs-top, $abs-leftcalc){
  @include content-abs();
  @include abs-top ($abs-top);
  @include abs-leftcalc ($abs-leftcalc);
}

// BEFORE/AFTER + TOP CALCUL PAR 50 + RIGHT ABSOLUTE
// ex : ((30px), 0);
@mixin abs-topcalcrightabs($abs-topcalc, $abs-right){
  @include content-abs();
  @include abs-topcalc ($abs-topcalc);
  @include abs-right ($abs-right);
}

// BEFORE/AFTER + TOP CALCUL PAR 50 + LEFT ABSOLUTE
// ex : ((30px), 0);
@mixin abs-topcalcleftabs($abs-topcalc, $abs-left){
  @include content-abs();
  @include abs-topcalc ($abs-topcalc);
  @include abs-left ($abs-left);
}

// ABSOLU + TOP CALCUL PAR 50 + LEFT ABSOLUTE
// ex : ((30px), 0);
@mixin absolute-topcalcleftabs($abs-topcalc, $abs-left){
  position: absolute;
  @include abs-topcalc ($abs-topcalc);
  @include abs-left ($abs-left);
}

// ABSOLU + TOP CALCUL PAR 50 + RIGHT ABSOLUTE
// ex : ((30px), 0);
@mixin absolute-topcalcrighttabs($abs-topcalc, $abs-right){
  position: absolute;
  @include abs-topcalc ($abs-topcalc);
  @include abs-right ($abs-right);
}

// ELLIPSIS OVERFLOW
@mixin ellipsis-text(){
  overflow:hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}

@mixin bgCover(){
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

// OBJECTFIT
@mixin objectFit($valWidth, $valHeight){
  object-fit:cover;
  width:$valWidth;
  height:$valHeight;
}

@mixin border($border-color){
  border-width: 1px;
  border-style:solid;
  border-color:$border-color;
}

//TRANSITION KIT
@mixin transition($kind, $rapidity, $type){
  -moz-transition: $kind $rapidity $type;
  -o-transition: $kind $rapidity $type;
  -webkit-transition: $kind $rapidity $type;
  transition: $kind $rapidity $type;
}

//TRANSITION WIDTH KIT
@mixin transition-width($type, $rapidity){
  -moz-transition: width $rapidity $type;
  -o-transition: width $rapidity $type;
  -webkit-transition: width $rapidity $type;
  transition: width $rapidity $type;
}
